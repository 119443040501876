import React from 'react';
import Navbar from './navbar';
import Footer from './footer';
import '../Components/home.css';
import {useNavigate} from "react-router-dom";

function Home(props) {
  const navigate = useNavigate();
    return (
      <>
      <Navbar></Navbar>
      <div className="hero-container">
        <div className="hero-content">
          <h1>VELKOMMEN TIL VIP CAB NORGE</h1>
          <h2>TJEN PENGER - BLI EN DEL AV VÅRT TEAM</h2>
          <img src="/assets/hero.png" alt="VIP Cab Cars" className="hero-image" />
          <h3>NYT DIN FREDELIG RITT MED OSS</h3>
          <p>
            Vi tilbyr den beste drosjeservicen i Norge. Vi tilbyr de rimeligste
            prisene fra hotell til flyplass, og langdistansereiser til byen. Vi
            har en luksusflåte i tillegg til et utdannet personale.
          </p>
          <button className="cta-button" onClick={()=> navigate("/booknow")}>Bestill nå <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
          </svg></button>
          <p><u>Utsikt Sentral oslo cabs</u></p>
        </div>
        <div className="services-container">
          <h1 className="services-header">Våre tjenester</h1>
          <div className="services-grid row">
            <div className="service-card">
              <div className="service-circle"></div>
              <h2>Oslo</h2>
              <p>Qorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className="service-card">
              <div className="service-circle"></div>
              <h2>Bergen</h2>
              <p>Qorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className="service-card">
              <div className="service-circle"></div>
              <h2>Bergen (Flesland)</h2>
              <p>Qorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
          <div className="services-grid row">
            <div className="service-card">
              <div className="service-circle"></div>
              <h2>Stavanger</h2>
              <p>Qorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className="service-card">
              <div className="service-circle"></div>
              <h2>Ålesund</h2>
              <p>Qorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className="service-card">
              <div className="service-circle"></div>
              <h2>Trondheim</h2>
              <p>Qorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='card-back'>
      <div className="cards-container">
      <div className="card">
        <div className="card-icon star-icon"></div>
        <div className="card-content">
          <h3>Registrer som løyvehaver</h3>
          <p>Vi kan hjelpe deg gjennom hele prosessen som kreves for at du<br/>skal kunne bli eier av en taxi.</p>
          <button className="register-button" onClick={()=> navigate("/registerowner")}>Registrer deg nå <svg xmlns="http://www.w3.org/2000/svg" width="16"       height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/></svg></button>
        </div>
        <div className="card-icon id-icon"></div>
      </div>
      <div className="card">
        <div className="card-icon star-icon"></div>
        <div className="card-content">
          <h3>Registrer som sjåfør</h3>
          <p>Registrer deg og få en lav sentralavgift som inkluderer alt til kr 2<br/>500,- per måned.</p>
          <button className="register-button" onClick={()=> navigate("/registerdriver")}>Registrer deg nå <svg xmlns="http://www.w3.org/2000/svg" width="16"       height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/></svg></button>
        </div>
        <div className="card-icon taxi-icon"></div>
      </div>
      <div className="card">
        <div className="card-icon star-icon"></div>
        <div className="card-content">
          <h3>Drosjesjåfør Kurs</h3>
          <p>For å ta Styringen Bak Rattet Som en Profesjonell Drosjesjåfør?<br/>Da Er Dette Kurset Akkurat Det Du Trenger</p>
          <button className="register-button">Registrer deg nå<svg xmlns="http://www.w3.org/2000/svg" width="16"       height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/></svg></button>
        </div>
        <div className="card-icon driver-icon"></div>
      </div>
    </div>
    </div>
  <div className="partner">
  <hr className="hr1" />
  <div className="content">
    <div className="p-image">
      <img src="/assets/img1.png" alt="VipCabs Car" />
    </div>
    <div className="p-text">
      <h1><b>BLI VÅR PARTNER OG TJEN SÅ MYE DU KAN</b></h1>
      <p>Hvordan skiller VipCabs seg ut fra andre taxiselskaper?</p>
      <ul>
        <li>VipCabs har trolig den laveste sentralavgiften i markedet.</li>
        <li>VipCabs har sjåførsupport 24/7 og er behjelpelig som sparringspartner hvis nødvendig.</li>
        <li>VipCabs har en sentralavgift som inkluderer alt på kr 2500,- pr mnd.</li>
      </ul>
    </div>
  </div>
  <hr className="hr2" />
</div>
<div className="choose-vipcabs">
  <div className="content">
    <div className="text">
      <h1>HVORFOR BØR DU VELGE VIPCabs?</h1>
      <p>Vi har tro på et godt lagarbeid og en kontinuerlig støtte til medlemmer av vårt team.<br/>
      For deg som ønsker å bli drosjeeier vil vi gjerne bistå med etablering av virksomhet, søknad om løyve, etablering av løyvegaranti og nødvendig kursing. Vi kan sørge for å hjelpe deg gjennom hele prosessen som kreves for at du skal bli en drosjeeier. Vi vil gjøre det lille ekstra og strekker oss langt for å bistå våre nye teammedlemmer.<br/>
      Registrer deg i dag og benytt muligheten for gratis montering av taksameterutstyr.</p>
    </div>
    <div className="image">
      <img src="/assets/img2.png" alt="VipCabs Car" />
    </div>
  </div>
  <div className="features">
    <div className="feature">
      <img src="/assets/Group1.png" alt="Sikkerhet" />
      <h2>Sikkerhet</h2>
      <p>Våre kunder og sjåfører sin sikkerhet er viktig for oss. Vi kurser derfor våre sjåfører, etter behov, spesielt om emner knyttet til sikkerhet. Vi har også iverksatt en del andre tiltak for å ivareta sikkerheten.</p>
    </div>
    <div className="feature">
      <img src="/assets/Group2.png" alt="Brukerstøtte" />
      <h2>Brukerstøtte</h2>
      <p>Vi ønsker å gjøre det lille ekstra for å rekruttere og beholde dyktige drosjeeiere og sjåfører. Vi er derfor tilgjengelige og tilbyr førsteklasses support til våre brukere.</p>
    </div>
    <div className="feature">
      <img src="/assets/Group3.png" alt="Pålitelig" />
      <h2>Pålitelig</h2>
      <p>Vi tror på en pålitelig tjeneste og ønsker å tiltrekke oss dyktige og serviceinnstilte drosjeeiere og sjåfører. Vi tror at fornøyde drosjeeiere og sjåfører vil gi fornøyde drosjekunder.</p>
    </div>
  </div>
</div>
<div className="driver">
  <hr className="hr1" />
  <div className="content">
    <div className="d-image">
      <img src="/assets/img3.png" alt="VipCabs Car" />
    </div>
    <div className="d-text">
      <h1><b>Bli med som sjåfør</b></h1>
      <p>Qorem ipsum dolor sit amet, consectetur adipiscing<br/> elit. Etiam eu turpis molestie, dictum est a, mattis <br/>tellus.Qorem ipsum dolor sit amet, consectetur<br/> adipiscing elit. Etiam eu turpis molestie, dictum est<br/> a, mattis tellus.</p>
      <button className='d-btn'onClick={()=> navigate("/contactus")}>Kontakt oss <svg xmlns="http://www.w3.org/2000/svg" width="16"       height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/></svg></button>
    </div>
  </div>
  <hr className="hr2" />
</div>
<div className="rider">
  <hr className="hr1" />
  <div className="content">
    <div className="r-text">
      <h1><b>Registrer bilen din hos oss</b></h1>
      <p>Qorem ipsum dolor sit amet, consectetur adipiscing<br/>elit. Etiam eu turpis molestie, dictum est a, mattis <br/>tellus.Qorem ipsum dolor sit amet, consectetur<br/> adipiscing elit. Etiam eu turpis molestie, dictum est<br/> a, mattis tellus.</p>
      <button className='r-btn' onClick={()=> navigate("/contactus")}>Kontakt oss <svg xmlns="http://www.w3.org/2000/svg" width="16"       height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/></svg></button>
    </div>
    <div className="r-image">
      <img src="/assets/img4.png" alt="VipCabs Car" />
    </div>
  </div>
  <hr className="hr2" />
</div>

<Footer></Footer>
      </>
    );
}

export default Home;