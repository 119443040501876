import React from 'react';
import "../Components/navbar.css";
import {useNavigate} from "react-router-dom";

function Navbar(props) {
    const navigate = useNavigate();
    return (
        <nav className="navbar navbar-expand-lg bg-body-black">
            <div className="container-fluid">
                <a className="navbar-brand" onClick={()=> navigate("/home")}>
                    <img src="/assets/logo.png" alt="Logo" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" role="img" focusable="false"><title>Menu</title><path stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M4 7h22M4 15h22M4 23h22"></path></svg></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0" id='nav-elements'>
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page"onClick={()=> navigate("/home")}>Hjem</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Tjenester
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Oslo</a></li>
                                <li><a className="dropdown-item" href="#">Bergen</a></li>
                                <li><a className="dropdown-item" href="#">Bergen (Flesland)</a></li>
                                <li><a className="dropdown-item" href="#">Stavanger</a></li>
                                <li><a className="dropdown-item" href="#">Alesund</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" onClick={()=> navigate("/contactus")}>Kontakt oss</a>
                        </li>
                    </ul>
                    <button className="btn ms-lg-3" id='nav-btn' onClick={()=> navigate("/booknow")}>Bestill nå &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" id='btn-icon' fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                    </button>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;